import React from 'react';
import Moment from 'moment';

import BasicLayout from 'components/basic-layout';
import TermsInline from '@youship/components/terms-inline';

const Terms = ({ pageContext: { locale } }) => {
  return (
    <BasicLayout locale={locale}>
      <TermsInline
            locale={locale}
      />
    </BasicLayout>
  );
};

export default Terms;
